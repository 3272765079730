<template>
    <div id="nav">
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>
    #nav {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
    }
</style>