<template>
    <div>
        <section class="bg-blue-500 py-20 ">
            <div class="container mx-auto px-5">
                <div class="text-left text-white">
                    <h1 class="text-4xl italic font-sans">一个酷爱编码的小程序员</h1>
                    <cite class="text-sm text-gray-200 font-serif">-- PHP/GO/JAVA/PYTHON</cite>
                </div>
                <div class="flex justify-end">
                    <img class="text-center" src="https://v2.jinrishici.com/one.svg?color=white" alt="">
                </div>
            </div>
        </section>
        <section class="bg-white py-20">
            <div class="container mx-auto px-5">
                <div class="text-left">
                    <p><cite class="text-gray-500 font-sans">我是个专业的软件开发工程师，不喜欢不搞花里胡哨的主页 :-)</cite></p>
                    <h2 class="text-2xl font-sans mt-8 mb-5">本站接单要求：</h2>
                    <p class="my-5">如果你是找我们做项目，请一定要仔细查看下面的要求：</p>
                    <ul>
                        <li class="mt-2">1. 本站只承接接 官网定制、商城定制、微信定制、ERP\OA\CRM\BPM...、系统定制等项目。</li>
                        <li class="mt-2">2. <span class="text-blue-500">本站只接程序实现 [code]，不承接设计项目。</span></li>
                        <li class="mt-2">3. 本站接单价格以W起，非诚勿扰，嫌贵勿扰！</li>
                        <li class="mt-2">4. 本站不参与比稿，接单要求预付50%，上线之后付剩下的50%。<span class="text-gray-600">[可开对公票据]</span>
                        </li>
                        <li class="mt-2">5. 不接没有设计稿和需求文档的项目。<span class="text-gray-600">[原型、业务流程图、功能思维导图Deng]</span>
                        </li>
                        <li class="mt-2">6. 期待更多变态要求......</li>
                    </ul>
                    <p class="mt-5">如果你能接受以上的条件，请直接发送你的需求到：
                        <a class="hover:text-blue-500" target="_blank"
                           href="mailto:&#102;&#0122;&#0112;&#00000121;&#105;&#000110;&#103;&#x000040;&#0049;&#0054;&#51;&#x02e;&#0000099;&#x0006f;&#0000109;">
                            &#102;&#0122;&#0112;&#00000121;&#105;&#000110;&#103;&#x000040;&#0049;&#0054;&#51;&#x02e;&#0000099;&#x0006f;&#0000109;
                        </a>
                    </p>
                </div>
            </div>
        </section>
        <section class="bg-gray-900 pt-20 pb-10">
            <div class="container mx-auto px-5">
                <div class="text-left text-white">
                    <p class="text-gray-400">ICP: <a class="hover:text-gray-200" href="http://www.beian.miit.gov.cn"
                                                     target="_blank">粤ICP备18051149号</a>
                    </p>
                    <p class="text-gray-400">© 2016 - {{ year }} <span>fzpying.com</span> &nbsp;&nbsp;
                        <span>5io.cc</span> 版权所有</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Welcome",
        data: function () {
            return {year: (new Date()).getFullYear()};
        }
    }
</script>

<style scoped>

</style>