<template>
    <div id="app">
        <header class="header">
            <Header/>
        </header>
        <div id="main" class="main">
            <router-view/>
        </div>
        <footer class="footer">
            <Footer/>
        </footer>
    </div>
</template>


<script>
    import Header from "./views/layout/Header";
    import Footer from "./views/layout/Footer";

    export default {
        components: {Footer, Header}
    }
</script>

<style>
    html {
        padding: 0;
        margin: 0;
    }

    body {
        padding: 0;
        margin: 0;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>